var ko = require("knockout");

var BrandingHelpers = require("../Core/BrandingHelpers");
var Constants = require("../Core/Constants");

var Error = Constants.Error;
var PaginatedState = Constants.PaginatedState;

module.exports = function (serverData)
{
    var _this = this;

    var _serverData = serverData;

    var _strings = _serverData.str;
    var _arrValErrs = _serverData.arrValErrs;
    var _allowCancel = _serverData.fAllowCancel;
    var _sErrTxt = _serverData.sErrTxt;
    var _urlCancel = _serverData.urlCancel;
    var _backgroundImage = _serverData.iBackgroundImage;
    var _isDebugTracingEnabled = _serverData.fIsDebugTracingEnabled;

    
    _this.initialViewId = PaginatedState.RemoteConnectCode;
    _this.currentViewId = PaginatedState.RemoteConnectCode;
    _this.initialSharedData = {};
    _this.debugDetails = {};
    _this.agreementType = null;
    _this.isDebugTracingEnabled = _isDebugTracingEnabled;

    _this.paginationControlMethods = ko.observable();
    _this.backgroundControlMethods = ko.observable();
    _this.instrumentationMethods = ko.observable();
    _this.footerMethods = ko.observable();
    _this.debugDetailsMethods = ko.observable();
    _this.forceSubmit = ko.observable(false);
    _this.useDefaultBackground = ko.observable(false);
    _this.fadeInLightBox = ko.observable(false);
    _this.isRequestPending = ko.observable(false);
    _this.showDebugDetails = ko.observable(false);

    
    _this.view_onSubmitReady = function ()
    {
        _this.instrumentationMethods().recordSubmit();
        _this.forceSubmit(true);
        _this.isRequestPending(true);
    };

    _this.view_onLoadView = function ()
    {
        return null;
    };

    _this.view_onShow = function ()
    {
        
        var branding = BrandingHelpers.getPageBranding(null, null, _backgroundImage);

        if (_this.backgroundControlMethods())
        {
            _this.backgroundControlMethods().updateBranding(branding);
        }

        _this.useDefaultBackground(branding.useDefaultBackground);
    };

    _this.view_onSetLightBoxFadeIn = function (fadeIn)
    {
        _this.fadeInLightBox(fadeIn);
    };

    _this.paginationControl_onCancel = function ()
    {
        if (_allowCancel)
        {
            document.location.assign(_urlCancel);
        }
    };

    _this.footer_agreementClick = function (agreementType)
    {
        _this.agreementType = agreementType;

        _this.paginationControlMethods().view_onSwitchView(PaginatedState.ViewAgreement);
    };

    _this.closeDebugDetails_onClick = function ()
    {
        _setDebugDetailsState(false);

        if (_this.footerMethods())
        {
            _this.footerMethods().setDebugDetailsState(false);
        }
    };

    _this.toggleDebugDetails_onClick = function ()
    {
        _setDebugDetailsState(!_this.showDebugDetails(), true);
    };

    _this.setDebugTracing_onClick = function ()
    {
        _this.isDebugTracingEnabled = !_this.isDebugTracingEnabled;
    };

    _this.view_onSetPendingRequest = function (pending)
    {
        _this.isRequestPending(pending);
    };
    

    
    _this.getServerError = function ()
    {
        if (_sErrTxt)
        {
            return _sErrTxt;
        }
        else if (_arrValErrs && _arrValErrs.length)
        {
            switch (_arrValErrs[0])
            {
                case Error.PP_E_OAUTH_REMOTE_CONNECT_USER_CODE_MISSING_OR_INVALID:
                    return _strings["RC_STR_EnterCode_Invalid_Error"];
                default:
                    return null;
            }
        }

        return null;
    };
    

    
    function _setDebugDetailsState(state, activateDebugDetailsFocus)
    {
        _this.showDebugDetails(state);

        if (state && activateDebugDetailsFocus)
        {
            _activateDebugDetailsFocus();
            return;
        }

        if (!state && _this.footerMethods())
        {
            _this.footerMethods().focusMoreInfoLink();
        }
    }

    function _activateDebugDetailsFocus()
    {
        if (_this.debugDetailsMethods())
        {
            _this.debugDetailsMethods().activateFocus();
        }
    }
    
};
