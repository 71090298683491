var ko = require("knockout");
var JSON = require("JSON");

var AsyncValidation = require("../Core/AsyncValidationControl");
var Constants = require("../Core/Constants");
var Browser = require("../Core/BrowserControl");
var Helpers = require("../Core/Helpers");
var ComponentEvent = require("../Core/ComponentEvent");
var PlaceholderTextbox = require("../Core/PlaceholderTextbox");
var ApiRequest = require("../Core/ApiRequest");
var ClientTracingConstants = require("../Core/ClientTracingConstants");

var w = window;
var QueryString = Browser.QueryString;
var StringHelpers = Helpers.String;


function RemoteConnectEnterCodeViewModel(params)
{
    var _this = this;
    var _appName = null;
    var c_nextButtonId = "idSIButton9";
    var _validationEnabled = ko.observable(false);
    var _asyncDeviceAuthComplete = false;

    
    var _serverData = params.serverData;
    var _serverError = params.serverError;

    var _strings = _serverData.str;
    var _asyncValidationEnabled = _serverData.fAsyncValidationEnabled;
    var _codeRegex = new RegExp(_serverData.sUserCodeRegex);
    var _deviceAuthUrl = _serverData.urlPost;
    var _checkApiCanary = _serverData.fCheckApiCanary;
    var _userCodePrefill = _serverData.sUserCodePrefill;

    
    _this.onSwitchView = ComponentEvent.create();
    _this.onSubmitReady = ComponentEvent.create();
    _this.onSetPendingRequest = ComponentEvent.create();

    
    _this.isRequestPending = ko.observable(false);
    _this.codeTextbox = new PlaceholderTextbox(AsyncValidation.errorComputed(_getError), _userCodePrefill);

    
    _this.saveSharedData = function (sharedData)
    {
        sharedData.appName = _appName;
        sharedData.code = _this.codeTextbox.value();
    };

    _this.getState = function ()
    {
        return null;
    };

    _this.restoreState = function ()
    {
    };

    _this.setDefaultFocus = function ()
    {
        _this.codeTextbox.focused(true);
    };
    

    
    _this.primaryButton_onClick = function ()
    {
        _this.codeTextbox.value(StringHelpers.trim(_this.codeTextbox.value()));
        _validationEnabled(true);

        if (_this.codeTextbox.error.isBlocking())
        {
            _this.setDefaultFocus();
            return;
        }

        if (_asyncValidationEnabled && !_asyncDeviceAuthComplete)
        {
            _setIsRequestPending(true);

            var apiRequest = new ApiRequest({ checkApiCanary: _checkApiCanary });
            apiRequest.Get(
                {
                    url: QueryString.addIfNotExist(_deviceAuthUrl, "code", _this.codeTextbox.value()),
                    eventId: ClientTracingConstants.EventIds.Api_DeviceAuthentication
                },
                Constants.ContentType.Json,
                _deviceAuth_onSuccess,
                _deviceAuth_onError,
                Constants.DefaultRequestTimeout);
        }
        else
        {
            _this.onSubmitReady();
        }
    };
    

    
    function _deviceAuth_onSuccess(event, response)
    {
        _setIsRequestPending(false);

        var result = JSON.parse(response);
        if (!result || result.Error)
        {
            _this.codeTextbox.error.setNonBlockingError(_strings["RC_STR_EnterCode_Invalid_Error"]);
            _this.setDefaultFocus();
        }
        else
        {
            _asyncDeviceAuthComplete = true;
            _this.onSubmitReady();

            
            var nextButton = document.getElementById(c_nextButtonId);
            if (nextButton)
            {
                nextButton.click();
            }
        }
    }

    function _deviceAuth_onError()
    {
        _setIsRequestPending(false);

        _this.codeTextbox.error.setNonBlockingError(_strings["RC_STR_EnterCode_Generic_Error"]);
        _this.setDefaultFocus();
    }

    function _getError()
    {
        var error;

        if (!_validationEnabled())
        {
            error = _serverError || null;
            _serverError = null;
            return error;
        }

        return _getClientError();
    }

    function _getClientError()
    {
        var code = StringHelpers.trim(_this.codeTextbox.value());

        if (!code)
        {
            return _strings["RC_STR_EnterCode_Empty_Error"];
        }

        if (_asyncValidationEnabled)
        {
            var upperCaseCode = code.toUpperCase();

            if (!_codeRegex.test(upperCaseCode))
            {
                return _strings["RC_STR_EnterCode_Invalid_Error"];
            }
        }

        return null;
    }

    function _setIsRequestPending(pending)
    {
        _this.isRequestPending(pending);
        _this.onSetPendingRequest(pending);
    }
    
}

ko.components.register("remote-connect-enter-code-view",
    {
        viewModel: RemoteConnectEnterCodeViewModel,
        template: require("html/RemoteConnectPage/RemoteConnectEnterCodeViewHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = RemoteConnectEnterCodeViewModel;